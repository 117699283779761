import React, { useContext, useState } from 'react'
import PageWrapper from 'UI/PageWrapper'
import formatNumber from 'utils/format-number'
import './friends.css'
import appContext from 'utils/app-context'
import Icon from 'UI/Icon'
import tg from 'utils/tg'
const tg_id = tg.initDataUnsafe.user?.id;
const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(tg_id)) || process.env.REACT_APP_SHARE_LINK || ''
const shareText = process.env.REACT_APP_SHARE_TEXT || '';
const Friends = () => {
  const {user} = useContext(appContext)
  const [copy, setcopy] = useState(false)
  if (!user) return null

  const copyShareLink = (e:React.MouseEvent) => {
    setcopy(true)
    tg.HapticFeedback.notificationOccurred('success')
    navigator.clipboard.writeText(inviteLink)
    setTimeout(() => setcopy(false),1000)
  }
  const TelegramShare = () => {
      tg.openTelegramLink(`https://t.me/share/url?url=${inviteLink}&text=${(shareText.replace(/\\n/g, '%0A'))}`)
  }

  const {friends,friends_list} = user
  return (
    <>
    <PageWrapper className='bg-opacity'>
      <h1>Invite your friends</h1>
      <div className="friends-info-blocks">
        <div className="friends-info-block bg-opacity active-item">
          <h3>Friends with
          Telegram Premium</h3>
          <div className="game-balance-value">
            <img src="img/coin.png" alt="" />
            <span>+ {formatNumber(20000)}</span>
          </div>
        </div>
        <div className="friends-info-block bg-opacity ">
          <h3>Invite Friends</h3>
          <div className="game-balance-value">
            <img src="img/coin.png" alt="" />
            <span>+ {formatNumber(10000)}</span>
          </div>
        </div>
      </div>
      <p>Your friends ({friends}):</p>
      <ul className="users-list" style={{marginBottom:20}}>
      {friends_list.map(({coins,name,photo}) => (
          <li className="users-list-item bg-opacity">
          <div className="user-info">
            <img src={photo} alt="" />
            <p>{name}</p>
          </div>
          <div className="game-balance-value">
            <img src="img/coin.png" alt="" />
            <span>+ {formatNumber(coins / 1000)}k</span>
          </div>
        </li>
        ))}
      </ul>
      
    </PageWrapper>
    <div className="invite-link-container">
    <button
      onClick={TelegramShare} 
      className='btn linear-bg invite-friend-btn'
    >Invite Friend</button>
    <button
      onClick={copyShareLink} 
      className='btn linear-bg copy-invite-link-btn'
      >
      <Icon icon={copy ? 'done' : 'copy'}/>
    </button>
    </div>
    </>
  )
}

export default Friends