import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from 'UI/PageWrapper'
import appContext from 'utils/app-context'
import './game.css'
import Icon from 'UI/Icon'
import formatNumber from 'utils/format-number'
import { motion } from 'framer-motion'
import tg from 'utils/tg'
import GameField from './GameField'

type Props = {}

const Game = (props: Props) => {
  const {user} = useContext(appContext)
  console.log("🚀 ~ Game ~ user:", user)
  const [showHint, setshowHint] = useState(false)
  const [showRules, setShowRules] = useState(false)
  
  // useEffect(() => {
  //   if (showRules) tg.disableVerticalSwipes()
  //   else tg.enableVerticalSwipes()
  // }, [showRules])

  if(!user) return null
  const {balance,balance_rs} = user
  return (
    <PageWrapper className='game-page'>
      <div className="game-top-bar">
        <div className="game-top-bar-profile bg-opacity">
          <img src={user.avatar} alt="" className='game-top-bar-profile-photo'/>
          <p className="game-top-bar-profile-name">{user.name}</p>
        </div>
        <button className='btn linear-bg' onPointerDown={() => setShowRules(true)}>Rules</button>
      </div>
      <GameField/>
      <div className="game-balance-wrapper">
        <motion.div
        initial={false}
        animate={{
          opacity:Number(showHint),
          y:showHint ? 0 : 100,
          zIndex:showHint ? 1 : -2
        }}
        className="game-page-hint bg-opacity">
          <button onPointerDown={() => setshowHint(false)}>
            <Icon icon='close'/>
          </button>
          <p>
          У вас есть два баланса (счета)<br/>
          Первый, это баланс игры, на нем отображается состояние счета в Талерах.<br/>
          Второй, это баланс токенов проекта, полученных в качестве призов.<br/>
          </p>
        </motion.div>
        <button
           onPointerDown={() => setshowHint(!showHint)}>
          <Icon icon='info'/>
        </button>
        <div className="game-balance-container bg-opacity">
          <div className="game-balance-value">
            <img src="img/coin.png" alt="" />
            <span>{formatNumber(balance)}</span>
          </div>
          <hr />
          <div className="game-balance-value">
            <img src="img/coin-v2.png" alt="" />
            <span>{formatNumber(balance_rs)}</span>
          </div>
        </div>
      </div>
      <motion.div
        className='game-rules-bg'
        initial={false}
        animate={{
          opacity:Number(showRules),
          zIndex:showRules ? 1 : -2
        }}
        onClick={() => setShowRules(false)}
      >
        <motion.div
          initial={false}
          animate={{
            y:showRules ? 0 : -100,
          }}
          className='game-rules-modal bg-opacity'
        onClick={(e) => e.stopPropagation()}
        >
            <button style={{padding:10}} onPointerDown={() => {setShowRules(false)}}>
              <Icon icon='close'/>
            </button>
            <p>
              CYBER Dart
              <br />
              <b>Самая простая игра, минимум усилий, минимум времени, максимум возможностей.</b>
              <br />
              <br />

              Игра построена на основе всем известного Darts 🎯
              <br />
              <br />

              В начале игры участник получает в своё распоряжение 1млн монет Талер (банк)<br />
              Каждые четыре часа игра забирает из банка 2777 монет. Игрок каждые четыре часа может сделать 10 бросков по мишени, полученные монеты вернутся в банк.<br />
              Всё что, за 60 дней, сможет сберечь игрок,остаток в банке, будет конвертировано в токены проекта.
              <br /><br />
              Метнешь дротик в мишень — заработаешь монеты.<br />
              Задача собрать максимальное количество очков и обменять их на токены проекта.<br /><br />

              В игре будут доступны  бонусы за различные активности, реферальная программа.<br /><br />

              Игра продлится два месяца и по её завершении мы проведём airdrop, где распределим 70% от общей эмиссии токенов. И, конечно, листинг токена.
              <br /><br />
              Для того, чтобы во время дропа участники получили достойное вознаграждение, мы установим ограничение на общее количество аккаунтов.
              <br /><br />
              Ещё одна важная фишка,
              в ходе <b>игры будет разыгран дополнительный призовой фонд</b>. Среди сегментов мишени будут, рандомно, скрыты призы:
              <br /><br />
              <ul>
                <li>1  10 тыс. призов по 1000 токенов</li>
                <li>2  5 тыс. призов по 10000 токенов</li>
                <li>3  1тыс. призов по 100 000 токенов</li>
                <li>4  90 призов по 1 000 000 токенов</li>
              </ul>
              И один приз 10 млн. токенов будет разыгран в финале игры.
              <br /><br />
              Важно заметить, что награда в розыгрыше начисляется не в очках игры, а в токенах проекта.<br />
              Благодаря этим розыгрышам, кое-кто из участников <b>может стать настоящим миллионером.</b><br /><br />

              В общем, мы предоставим для вас, простой, динамичный, комфортный в использовании инструмент для справедливого распределения токенов проекта CYBEX.
              <br /><br />
              Правила игры
              1. В начале игры участник получает 1 млн. монет Талер ( Талер внутри игровая монета) 
              <br /><br />
              2. Задача каждого участника — сохранить как можно больше Талеров.
              <br /><br />
              3. Каждые 4 часа игра забирает из вашего банка 2777 Талеров. Ваша задача вернуть в банк максимально большее количество монет. Бросаете дротик в мишень получаете монеты, выйгрыш возвращается в ваш банк.
              <br /><br />
              4. Мишень состоит из 20 равных секторов. Сектора пронумерованы от 10 до 200. При попадании в каждый из них начисляются монеты, в количестве соответствующем номеру сектора.
              <br /><br />
              5. На мишени есть разметка из колец. Если участник попадает во внешнее кольцо, то очки удваиваются, а если попадает во внутреннее, то очки утраиваются.
              <br /><br /> 

              6. За попадание в «яблочко» можно списать 500 монет, а в область вокруг него — 250.
              <br /><br /> 

              7. Больше всего очков приносит попадание во внутреннее кольцо 200 сектора. После утроения участник списывает 600 очков.
              <br /><br /> 

              8. За одну серию каждый игрок делает по десять бросков.
              <br /><br /> 

              9. Набранные баллы суммируются и фиксируются на счету участника.   
              <br /><br /> 

              10. Повторить подход можно один раз в четыре часа.
              <br /><br /> 

              11. Все Талеры оставшиеся в вашем банке ( счете) в конце игры будут конвертированы в .токены проекта.
            </p>
        </motion.div>
      </motion.div>
    </PageWrapper>
  )
}

export default Game