import { animate, AnimatePresence, motion, Variants } from "framer-motion";
import React, { useEffect, useState } from "react";
import Icon from "UI/Icon";

type Props = {
  isPlaying: boolean;
  isMoving: boolean;
  isThrows: boolean;
};

const variants: Variants = {
  start: {
    y: -100,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 100,
    opacity: 0,
  },
};

const LearnGame = ({ isMoving, isPlaying, isThrows }: Props) => {
  const [stage, setstage] = useState(0);
    const [isShowed, setIsShowed] = useState(!!localStorage.getItem('showed-learn'))
  const props = {
    variants: variants,
    initial: "start",
    animate: "animate",
    exit: "exit",
    key: stage,
    className: "game-page-hint learn-game-hint bg-opacity",
  };
  useEffect(() => {
    if (!isPlaying || isShowed) return
    if(isThrows) {
        localStorage.setItem('showed-learn','true')
        setIsShowed(true)
        setstage(0)
        return
    }
    if (!isMoving) setstage(2)
    else if(isMoving) setstage(1)
  }, [isMoving, isPlaying, isThrows])
  return (
    <AnimatePresence>
      {stage == 1 && (
        <motion.div {...props}>
          <p>Tap the screen to stop the dart.</p>
        </motion.div>
      )}
      {stage == 2 && <motion.div {...props}>
        <p>Swipe up your finger on the screen to to launch a dart.</p>
        <Icon icon="swipe"/>
        </motion.div>}
    </AnimatePresence>
  );
};

export default LearnGame;
