import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from 'UI/PageWrapper'
import './tasks.css'
import getTasks from 'api/get-tasks'
import appContext from 'utils/app-context'
import tg from 'utils/tg'
import doneTask from 'api/done-task'
import Task from 'types/task'
import formatNumber from 'utils/format-number'
import Icon from 'UI/Icon'


const Tasks = () => {
  const {user,setUser} = useContext(appContext)
  const [tasks, setTasks] = useState<Task[] | null>(null);
  const [pendingTasks, setPendingTasks] = useState<string[]>([]);
  const [category, setcategory] = useState<TaskCategory>(categories[0])
  const updateTasks = async () => {
    const result = await getTasks();
    if (result) setTasks(result.tasks);
  };

  const claimTask = (taskID:string,url:string) => {
    setPendingTasks(prev => [...prev,taskID])
    try {
      tg.openTelegramLink(url)
    } catch (error) {
      tg.openLink(url)
    }
    const HandleTask = async () => {
        const result = await doneTask(taskID)
        const err = () => tg.showAlert('There was some kind of error')
        if (result && result.success) {
          updateTasks()
          const {balance,balance_rs} = result
          if(user) setUser({...user,balance,balance_rs})
        } else err()
      setPendingTasks(prev => prev.filter(id => id !== taskID))
    } 

    setTimeout(HandleTask,3000)
  }

  useEffect(() => {
    updateTasks();
  }, []);
  return (
    <PageWrapper className='bg-opacity'>
      <h1>Tasks</h1>
      <ul className='tasks-categories-list'>
        {categories.map((c,i) => {
          const {title} = c
          const handleClick = () => setcategory(c)
          return (
            <li
              onClick={handleClick}
              className={`tasks-category ${title === category.title && 'selected'}`}
            >
              <button>{title}</button>
            </li>
          )
        })}
      </ul>
      <ul className="tasks-list bg-opacity">
        {tasks?.filter(({status}) => status === category.status).length === 0 && <p>There are no tasks</p>}
        {tasks
        ? tasks.map(({id,title,reward,url,icon,status}) => {
          if (category.status !== status) return null
          const isPending = pendingTasks.findIndex(idT => id === idT) !== -1
          return (
            <li key={id} className="tasks-list-item" onClick={!category.status ? (() => claimTask(id,url)) : undefined}>
            <div className="tasks-list-item-title">
              <img src={`img/task-icons/${icon}`} alt="" />
              <h4>{title}</h4>
            </div>
            <div className="tasks-list-item-reward">
              <img src="img/coin.png" alt="" />
              <span>+ {formatNumber(reward)}</span>
              {!category.status && <Icon icon={isPending ? 'loading' : 'arrowRight'}/>}
            </div>
          </li>
          )
        })
        : <Icon icon='loading'/>
        }
      </ul>
    </PageWrapper>
  )
}

type TaskCategory = {
  title:string,
  status?:Task['status']
  message?:string
}
const categories:TaskCategory[] = [
  {
    title:'Tasks',
    status:0,
  },
  {
    title:'Pending',
    status:2
  },
  {
    title:'Done',
    status:1
  },
]


export default Tasks