import React, { useEffect, useRef } from 'react';
import dartTarget from 'images/darts-target.svg';

const DartsTargetBack = () => {
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const updateImageSize = () => {
      // Определяем высоту экрана
      const screenHeight = window.innerHeight;
      // Соотношение фона и картинки
      const ratio = 370 / 1113; // высота картинки dartTarget относительно высоты фона
      // Вычисляем новую высоту картинки
      const newHeight = screenHeight * ratio;
      // Применяем высоту к элементу изображения
      if (imgRef.current) {
        imgRef.current.style.height = `${newHeight}px`;
      }
    };

    // Обновляем размер изображения при загрузке и изменении размеров экрана
    updateImageSize();
    window.addEventListener('resize', updateImageSize);

    return () => {
      window.removeEventListener('resize', updateImageSize);
    };
  }, []);

  return (
    <section className='darts-target-bg' style={{ height: '100vh' }}>
      <img className='darts-target' ref={imgRef} src={dartTarget} alt="Darts Target" />
    </section>
  );
};

export default DartsTargetBack;
