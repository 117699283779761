import React, { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    icon:keyof typeof icons
}

const Icon = (props: Props) => {
  const icon = icons[props.icon]
//   icon.props = props
return icon
}

const icons = {
    game:(
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 15.5H5C4.20435 15.5 3.44129 15.8161 2.87868 16.3787C2.31607 16.9413 2 17.7044 2 18.5C2 19.2956 2.31607 20.0587 2.87868 20.6213C3.44129 21.1839 4.20435 21.5 5 21.5H19C19.7956 21.5 20.5587 21.1839 21.1213 20.6213C21.6839 20.0587 22 19.2956 22 18.5C22 17.7044 21.6839 16.9413 21.1213 16.3787C20.5587 15.8161 19.7956 15.5 19 15.5ZM18 11.5C19.0609 11.5 20.0783 11.0786 20.8284 10.3284C21.5786 9.57828 22 8.56087 22 7.5C22 6.43913 21.5786 5.42172 20.8284 4.67157C20.0783 3.92143 19.0609 3.5 18 3.5C16.9391 3.5 15.9217 3.92143 15.1716 4.67157C14.4214 5.42172 14 6.43913 14 7.5C14 8.56087 14.4214 9.57828 15.1716 10.3284C15.9217 11.0786 16.9391 11.5 18 11.5ZM2 7.5L6.5 3L11 7.5L6.5 12L2 7.5Z" fill="white"/>
        </svg>
        
    ),
    friends:(
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.15299 5.908C10.42 3.636 11.053 2.5 12 2.5C12.947 2.5 13.58 3.636 14.847 5.908L15.175 6.496C15.535 7.142 15.715 7.465 15.995 7.678C16.275 7.891 16.625 7.97 17.325 8.128L17.961 8.272C20.421 8.829 21.65 9.107 21.943 10.048C22.235 10.988 21.397 11.969 19.72 13.93L19.286 14.437C18.81 14.994 18.571 15.273 18.464 15.617C18.357 15.962 18.393 16.334 18.465 17.077L18.531 17.754C18.784 20.371 18.911 21.679 18.145 22.26C17.379 22.841 16.227 22.311 13.925 21.251L13.328 20.977C12.674 20.675 12.347 20.525 12 20.525C11.653 20.525 11.326 20.675 10.672 20.977L10.076 21.251C7.77299 22.311 6.62099 22.841 5.85599 22.261C5.08899 21.679 5.21599 20.371 5.46899 17.754L5.53499 17.078C5.60699 16.334 5.64299 15.962 5.53499 15.618C5.42899 15.273 5.18999 14.994 4.71399 14.438L4.27999 13.93C2.60299 11.97 1.76499 10.989 2.05699 10.048C2.34899 9.107 3.57999 8.828 6.03999 8.272L6.67599 8.128C7.37499 7.97 7.72399 7.891 8.00499 7.678C8.28599 7.465 8.46499 7.142 8.82499 6.496L9.15299 5.908Z" fill="white"/>
        </svg>
        
    ),
    rating:(
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 8.66192V8.73492C22 9.59492 22 10.0259 21.793 10.3779C21.586 10.7299 21.209 10.9389 20.457 11.3579L19.664 11.7979C20.21 9.94992 20.393 7.96392 20.46 6.26592L20.47 6.04492L20.472 5.99292C21.123 6.21892 21.489 6.38792 21.717 6.70392C22 7.09692 22 7.61892 22 8.66192ZM2 8.66192V8.73492C2 9.59492 2 10.0259 2.207 10.3779C2.414 10.7299 2.791 10.9389 3.543 11.3579L4.337 11.7979C3.79 9.94992 3.607 7.96392 3.54 6.26592L3.53 6.04492L3.529 5.99292C2.877 6.21892 2.511 6.38792 2.283 6.70392C2 7.09692 2 7.61992 2 8.66192Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.377 2.84698C14.9302 2.60998 13.4661 2.49391 12 2.49998C10.217 2.49998 8.74701 2.65698 7.62301 2.84698C6.48401 3.03898 5.91501 3.13498 5.43901 3.72098C4.96401 4.30698 4.98901 4.93998 5.03901 6.20598C5.21201 10.554 6.15001 15.986 11.25 16.466V20H9.82001C9.58891 20.0001 9.365 20.0803 9.18634 20.2269C9.00768 20.3734 8.8853 20.5774 8.84001 20.804L8.65001 21.75H6.00001C5.80109 21.75 5.61033 21.829 5.46968 21.9697C5.32902 22.1103 5.25001 22.3011 5.25001 22.5C5.25001 22.6989 5.32902 22.8897 5.46968 23.0303C5.61033 23.171 5.80109 23.25 6.00001 23.25H18C18.1989 23.25 18.3897 23.171 18.5303 23.0303C18.671 22.8897 18.75 22.6989 18.75 22.5C18.75 22.3011 18.671 22.1103 18.5303 21.9697C18.3897 21.829 18.1989 21.75 18 21.75H15.35L15.16 20.804C15.1147 20.5774 14.9923 20.3734 14.8137 20.2269C14.635 20.0803 14.4111 20.0001 14.18 20H12.75V16.466C17.85 15.986 18.789 10.555 18.961 6.20598C19.011 4.93998 19.037 4.30598 18.561 3.72098C18.085 3.13498 17.516 3.03898 16.377 2.84698Z" fill="white"/>
        </svg>
        
        
    ),
    tasks:(
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_6053_238)">
        <path d="M1.85706 18.513L11.7361 24.5V12.956L1.85706 6.968V18.513ZM22.1431 18.513V6.968L12.2641 12.956V24.5L22.1431 18.513ZM21.8971 6.499L12.0001 0.5L2.10306 6.499L12.0001 12.498L21.8971 6.499Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_6053_238">
        <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
        </svg>
        
    ),
    info:(
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5 1.5C15.285 1.5 17.9559 2.60633 19.9252 4.5756C21.8944 6.54487 23.0008 9.21578 23.0008 12.0007C23.0008 14.7857 21.8944 17.4566 19.9252 19.4259C17.9559 21.3952 15.285 22.5015 12.5 22.5015C9.71505 22.5015 7.04414 21.3952 5.07487 19.4259C3.10559 17.4566 1.99927 14.7857 1.99927 12.0007C1.99927 9.21578 3.10559 6.54487 5.07487 4.5756C7.04414 2.60633 9.71505 1.5 12.5 1.5ZM14.075 7.947C14.855 7.947 15.488 7.4055 15.488 6.603C15.488 5.8005 14.8535 5.259 14.075 5.259C13.295 5.259 12.665 5.8005 12.665 6.603C12.665 7.4055 13.295 7.947 14.075 7.947ZM14.3495 16.3875C14.3495 16.227 14.405 15.81 14.3735 15.573L13.1405 16.992C12.8855 17.2605 12.566 17.4465 12.416 17.397C12.348 17.372 12.2911 17.3235 12.2556 17.2602C12.2201 17.197 12.2084 17.1231 12.2225 17.052L14.2775 10.56C14.4455 9.7365 13.9835 8.985 13.004 8.889C11.9705 8.889 10.4495 9.9375 9.52402 11.268C9.52402 11.427 9.49402 11.823 9.52552 12.06L10.757 10.6395C11.012 10.374 11.309 10.1865 11.459 10.2375C11.5329 10.264 11.5935 10.3185 11.6277 10.3891C11.6619 10.4598 11.667 10.5411 11.642 10.6155L9.60502 17.076C9.36952 17.832 9.81502 18.573 10.895 18.741C12.485 18.741 13.424 17.718 14.351 16.3875H14.3495Z" fill="white" fill-opacity="0.6"/>
        </svg>
    ),
    arrowRight:(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>        
    ),
    copy:(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9638 8.98209C16.9614 6.03194 16.9168 4.50384 16.0579 3.45753C15.8921 3.25546 15.7068 3.07019 15.5048 2.90436C14.4009 1.99854 12.761 1.99854 9.48093 1.99854C6.20095 1.99854 4.56096 1.99854 3.45714 2.90436C3.25506 3.07018 3.06977 3.25546 2.90393 3.45753C1.99805 4.56128 1.99805 6.20116 1.99805 9.48091C1.99805 12.7607 1.99805 14.4005 2.90393 15.5043C3.06976 15.7063 3.25506 15.8916 3.45714 16.0574C4.50352 16.9162 6.03173 16.9608 8.98207 16.9632" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.0284 9.02455L16.9941 8.98193M14.0144 22.0013L16.98 21.9586M21.9717 14.0221L21.9437 16.9818M9.01039 14.0357L8.98242 16.9953M11.4874 9.02455C10.6546 9.17371 9.31787 9.32713 9.01039 11.0488M19.4947 21.9586C20.3297 21.8223 21.6686 21.6894 22.0026 19.9726M19.4947 9.02455C20.3275 9.17371 21.6642 9.32713 21.9717 11.0488M11.5001 21.9573C10.6673 21.8086 9.33045 21.6559 9.02203 19.9344" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>             
    ),
    done:(
        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7071 6.29289C20.0976 6.68342 20.0976 7.31658 19.7071 7.70711L10.4142 17C9.63316 17.7811 8.36683 17.781 7.58579 17L3.29289 12.7071C2.90237 12.3166 2.90237 11.6834 3.29289 11.2929C3.68342 10.9024 4.31658 10.9024 4.70711 11.2929L9 15.5858L18.2929 6.29289C18.6834 5.90237 19.3166 5.90237 19.7071 6.29289Z" fill="#ffffff"/>
        </svg>         
    ),
    close:(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM15.5306 14.4694C15.6003 14.5391 15.6556 14.6218 15.6933 14.7128C15.731 14.8039 15.7504 14.9015 15.7504 15C15.7504 15.0985 15.731 15.1961 15.6933 15.2872C15.6556 15.3782 15.6003 15.4609 15.5306 15.5306C15.4609 15.6003 15.3782 15.6556 15.2872 15.6933C15.1961 15.731 15.0986 15.7504 15 15.7504C14.9015 15.7504 14.8039 15.731 14.7128 15.6933C14.6218 15.6556 14.5391 15.6003 14.4694 15.5306L12 13.0603L9.53063 15.5306C9.46095 15.6003 9.37822 15.6556 9.28718 15.6933C9.19613 15.731 9.09855 15.7504 9 15.7504C8.90146 15.7504 8.80388 15.731 8.71283 15.6933C8.62179 15.6556 8.53906 15.6003 8.46938 15.5306C8.3997 15.4609 8.34442 15.3782 8.30671 15.2872C8.269 15.1961 8.24959 15.0985 8.24959 15C8.24959 14.9015 8.269 14.8039 8.30671 14.7128C8.34442 14.6218 8.3997 14.5391 8.46938 14.4694L10.9397 12L8.46938 9.53063C8.32865 9.38989 8.24959 9.19902 8.24959 9C8.24959 8.80098 8.32865 8.61011 8.46938 8.46937C8.61011 8.32864 8.80098 8.24958 9 8.24958C9.19903 8.24958 9.3899 8.32864 9.53063 8.46937L12 10.9397L14.4694 8.46937C14.5391 8.39969 14.6218 8.34442 14.7128 8.3067C14.8039 8.26899 14.9015 8.24958 15 8.24958C15.0986 8.24958 15.1961 8.26899 15.2872 8.3067C15.3782 8.34442 15.4609 8.39969 15.5306 8.46937C15.6003 8.53906 15.6556 8.62178 15.6933 8.71283C15.731 8.80387 15.7504 8.90145 15.7504 9C15.7504 9.09855 15.731 9.19613 15.6933 9.28717C15.6556 9.37822 15.6003 9.46094 15.5306 9.53063L13.0603 12L15.5306 14.4694Z" fill="white"/>
        </svg>
    ),
    swipe:(
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.125 2.50488V10.0019M23.125 2.50488C22.2497 2.50488 20.6144 4.99678 20 5.62866M23.125 2.50488C24.0003 2.50488 25.6356 4.99678 26.25 5.62866" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.1165 27.4852C21.0519 25.0937 21.213 24.8058 21.3839 24.2738C21.5547 23.7418 22.7501 21.8231 23.1731 20.4524C24.5415 16.0175 23.2661 15.0742 21.5657 13.8166C19.6801 12.4219 16.1235 11.7156 14.3596 11.8685V4.67946C14.3596 3.47578 13.3834 2.5 12.1793 2.5C10.9751 2.5 9.99895 3.47578 9.99895 4.67946V17.5039L7.42374 14.7796C6.62421 13.9127 5.33932 13.8249 4.4624 14.6134C3.63139 15.3606 3.51011 16.619 4.18315 17.5111L5.79857 19.6522M5.79857 19.6522C6.14645 20.1039 6.53586 20.6155 6.97843 21.2161M5.79857 19.6522L6.97843 21.2161M5.79857 19.6522C5.08436 18.7251 4.54521 18.0513 4.08041 17.3819M9.83725 27.5L9.81283 26.187C9.86651 24.6479 8.74652 23.6436 7.2858 21.6359C7.18056 21.4913 7.07815 21.3514 6.97843 21.2161M6.97843 21.2161L8.44021 23.1536" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    ),
    loading:(
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 128 128" ><g><path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" fill="#ffffff"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite"></animateTransform></g></svg>
    )
}

export default Icon