import { motion } from "framer-motion";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "routes";
import tg from "utils/tg";

type Props = {};

const Navigation = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentLoc = routes.find(
    ({ route: { path } }) => location.pathname === path
  );

  if (!currentLoc?.navBarIcon) return null;

  return (
    <nav className="navbar">
      <ul className="navbar-list">
        {routes.map(({ route: { path }, title, navBarIcon }) => {
          if (!navBarIcon) return null;
          const handleClick = () => {
            navigate(path || "");
            tg.HapticFeedback.selectionChanged()
          }
          const iscurrentRoute = location.pathname === path;
          return (
            <motion.li
              whileTap={{scale:0.9,}}
              transition={{duration:0.001}}
              className={`navbar-item ${iscurrentRoute && "active-item"} bg-opacity`}
              key={path}
              onPointerDown={handleClick}
            >
              <button>
                {navBarIcon}
                <p
                  className={`navbar-item-text ${
                    iscurrentRoute && "sidebar__text_first"
                  }`}
                >
                  {title}
                </p>
              </button>
            </motion.li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navigation;
