import getRating from 'api/get-rating'
import React, { useEffect, useState } from 'react'
import RatingInfo from 'types/rating-info'
import Icon from 'UI/Icon'
import PageWrapper from 'UI/PageWrapper'
import formatNumber from 'utils/format-number'
import tg from 'utils/tg'

type Props = {}

const Rating = (props: Props) => {
  const [rating, setrating] = useState<RatingInfo | null>(null)


  const updateRating = async () => {
    const result = await getRating()
    if(result) setrating(result)
  }
  useEffect(() => {
    updateRating()
  }, [])
  return (
    <PageWrapper className='bg-opacity'>
      <h1>Rating</h1>
      <ul className="users-list" style={{marginTop:24}}>
        {rating ? rating.top_list.map(({coins,name,number,photo}) => {
          const isMy = number === rating.my.number
          return (
            <li className={`users-list-item bg-opacity ${isMy && 'my-position active-item'}`}>
            <div className="user-info">
              <div className='users-list-position'>
                <span>{number}</span>
              </div>
              <img src={photo} alt="" />
              <p>{name}</p>
            </div>
            <div className="game-balance-value">
              <img src="img/coin.png" alt="" />
              <span>{formatNumber(coins / 1000)} k</span>
            </div>
          </li>
          )
        }) : <Icon icon='loading'/>}
      </ul>
    </PageWrapper>
  )
}

export default Rating