import React, { useState, useEffect, useRef } from "react";
import { motion, TargetAndTransition, Variant, Variants } from "framer-motion";
import dart from 'images/icons/dart.svg'; // Ваш иконка дротика
import useSwipe, { SwipeData } from "hooks/use-swipe";
import tg from "utils/tg";
import LearnGame from "./components/LearnGame";

const GameField = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMoving, setIsMoving] = useState(true);
  const [isThrows, setIsThrows] = useState(false);
  const [currentX, setCurrentX] = useState(0); // Текущее положение дротика
  const [containerWidth, setContainerWidth] = useState(0);
  const { swipeProps, swipeData,setSwipeData } = useSwipe();
  const containerRef = useRef<HTMLDivElement>(null);
  const [oldDarts, setoldDarts] = useState<JSX.Element[]>([])
  const [id, setid] = useState(Date.now())
  // Рассчитываем ширину контейнера после монтирования компонента
  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, [isPlaying]);

  // Ограничиваем движение дротика шириной контейнера
  const dartVariants:Variants = {
    move: {
      x: [0, containerWidth - 50], // Диапазон движения (50 - это ширина дротика)
      transition: {
        x: {
          repeat: Infinity,
          repeatType: "reverse",
          duration: 2 // Скорость движения
        }
      }
    },
    stop: {
      x: currentX // Остановка дротика на его текущей позиции
    },
    throw:getAnimationTrajectory(swipeData || {angle:0,speed:0},currentX)
  };

  
  useEffect(() => {
    if (!isPlaying || isMoving) return
    if(swipeData && swipeData?.speed > 0.1) setIsThrows(true)    
  }, [swipeData])
  return (
    <div className="game-field" {...swipeProps}>
      {!isPlaying && (
        <button
          className="btn linear-bg shadow start-game"
          onClick={() => setIsPlaying(true)}
        >
          Play
        </button>
      )}
      <LearnGame isMoving={isMoving} isPlaying={isPlaying} isThrows={isThrows}/>
      {isPlaying && (
        <div
          className="dart-container"
          ref={containerRef} // Используем ref для получения размера контейнера
          onClick={() => {
            setIsMoving(false); // Останавливаем движение
          }}
        >
           {oldDarts}
          <motion.img
            id={String(id)}
            src={dart}
            alt="dart"
            className="dart"
            draggable="false"
            variants={dartVariants}
            animate={isMoving ? "move" : (isThrows ? 'throw' : "stop")}
            onUpdate={(latest) => {
              if (isMoving) {
                setCurrentX(Number(latest.x)); // Обновляем текущее положение по оси X
              }
            }}
            onAnimationComplete={() => {
                if (isThrows && isPlaying && !isMoving) {
                    tg.HapticFeedback.impactOccurred('medium')
                    const {x,y,scale,rotateX} = dartVariants.throw as TargetAndTransition
                    setoldDarts([...oldDarts,(
                        <motion.img 
                        className="dart"
                        src={dart} id={String(id)}
                        style={{
                            transform:`translateX(${x}px) translateY(${y}px) scale(${scale}) rotateX(${rotateX}deg)`
                        }}
                        />
                    )])
                    setid(Date.now())
                    setIsMoving(true)
                    setIsThrows(false)
                    setSwipeData(null)
                }
            }}
          />
        </div>
      )}
    </div>
  );
};
const getAnimationTrajectory = (swipeData: SwipeData, x?: number):Variant => {
    const { angle, speed } = swipeData;
  
    // Увеличиваем длительность анимации
    const baseDuration = Math.max(0.2, speed / 100); // Увеличиваем минимальную длительность
  
    // Установим фиксированное значение для rotateX
    const rotateX = 115; // Угол наклона
  
    // Определяем параметры для дуги
    const distance = speed * 120; // Дистанция будет равна скорости
    const radians = (angle * Math.PI) / 180; // Преобразование градусов в радианы
  
    // Вычисляем конечные значения для x и y
    const xEnd = (distance * Math.cos(radians)) + (x || 0); // Конечное значение по X
    const yEnd = (distance * Math.sin(radians)); // Конечное значение по Y
  
    // Расчет длительности для rotateX на основе расстояния
    const totalDistance = Math.sqrt(Math.pow(xEnd, 2) + Math.pow(yEnd, 2));
    const duration = baseDuration + totalDistance / 10000; // Измените 500 на значение, которое вам подходит для расчета
  
    // Масштаб для создания эффекта отдаления
    const scale = 0.7; // Устанавливаем статическое значение масштаба
  
    return {
      x: xEnd, // Конечное значение по оси X
      y: yEnd, // Конечное значение по оси Y
      rotateX: rotateX, // Статическое значение угла наклона
      scale: scale, // Статическое значение масштаба
        transition:{
            duration, // Длительность анимации
        }
    };
  };

export default GameField;
