import tg from "utils/tg";
import UserData from "types/user-data";
import Task from "types/task";
import RatingInfo from "types/rating-info";
const tg_id = tg.initDataUnsafe.user?.id;



export default async ():Promise<RatingInfo | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "rating";
    API_URL.searchParams.set("tg_id", String(tg_id));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    return null
  }
};
