import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageWrapper from 'UI/PageWrapper';
import './loading.css';

type Props = {};

const Loading = (props: Props) => {
  const [progress, setProgress] = useState(20);
  const navigate = useNavigate();

  // Effect for animating the progress
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        const nextProgress = prev + 1; // Increase progress by 5
        if (nextProgress >= 100) {
          clearInterval(interval);
          setTimeout(() => {
            navigate('/game'); // Navigate to /game when progress reaches 100
          }, 200); // Optional delay before navigation
        }
        return Math.min(nextProgress, 100); // Ensure it doesn't go beyond 100
      });
    }, 20); // Increase progress every 0.5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [navigate]);

  return (
    <PageWrapper className='loading-page'>
      <div className="loading-container">
        <span className="loading-progress-value">{progress}%</span>
        <div className="loading-progress-bar bg-opacity">
          <div
            className="loading-progress-bar-body linear-bg"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
    </PageWrapper>
  );
};

export default Loading;
