import { useRef, useState, useCallback } from 'react';

export interface SwipeData {
  angle: number;
  speed: number;
}

const useSwipe = () => {
  const [swipeData, setSwipeData] = useState<SwipeData | null>(null);
  const startRef = useRef<{ x: number; y: number; time: number } | null>(null);

  const handleStart = useCallback((x: number, y: number) => {
    startRef.current = {
      x,
      y,
      time: Date.now(),
    };
  }, []);

  const handleEnd = useCallback((endX: number, endY: number) => {
    if (!startRef.current) return;

    const endTime = Date.now();

    const deltaX = endX - startRef.current.x;
    const deltaY = endY - startRef.current.y;
    const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
    const time = endTime - startRef.current.time;

    const speed = distance / time; // px/ms
    const angle = (Math.atan2(deltaY, deltaX) * 180) / Math.PI; // угол в градусах

    setSwipeData({ angle, speed });
    startRef.current = null; // Сброс состояния
  }, []);

  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    const touch = e.touches[0];
    handleStart(touch.clientX, touch.clientY);
  }, [handleStart]);

  const handleTouchEnd = useCallback((e: React.TouchEvent) => {
    const touch = e.changedTouches[0];
    handleEnd(touch.clientX, touch.clientY);
  }, [handleEnd]);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    handleStart(e.clientX, e.clientY);
  }, [handleStart]);

  const handleMouseUp = useCallback((e: React.MouseEvent) => {
    handleEnd(e.clientX, e.clientY);
  }, [handleEnd]);

  const swipeProps = {
    onTouchStart: handleTouchStart,
    onTouchEnd: handleTouchEnd,
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
  };

  return { swipeProps, swipeData,setSwipeData };
};

export default useSwipe;
