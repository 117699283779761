import Friends from "pages/Friends/Friends";
import Game from "pages/Game/Game";
import Loading from "pages/Loading/Loading";
import Rating from "pages/Rating/Rating";
import Tasks from "pages/Tasks/Tasks";
import { RouteObject } from "react-router-dom";
import Icon from "UI/Icon";

type Route = {
  route:RouteObject
  title?:string
  navBarIcon?:JSX.Element
}

const routes:Route[] = [
    {
        route:{
          path: "/",
          element: <Loading />,
        },
        title:'Главная',
      },
    {
      route:{
        path: "/game",
        element: <Game />,
      },
      title:'Game',
      navBarIcon:<Icon icon="game"/>,
    },
    {
      route:{
        path: "/friends",
        element: <Friends />,
      },
      title:'Friends',
      navBarIcon:<Icon icon="friends"/>,
    },
    {
      route:{
        path: "/rating",
        element: <Rating />,
      },
      title:'Rating',
      navBarIcon:<Icon icon="rating"/>,
    },
    {
      route:{
        path: "/tasks",
        element: <Tasks />,
      },
      title:'Tasks',
      navBarIcon:<Icon icon="tasks"/>,
    },
  ]
  export default routes