import { RouterProvider, useLocation, useNavigate, useNavigationType, useRoutes } from "react-router-dom";
import useAppData from "./hooks/use-app-data";
import routes from "./routes";
import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import Navigation from "UI/Navigation";
import tg from "utils/tg";
import DartsTargetBack from "UI/DartsTargetBack";

function App() {
  const navigation = useNavigate()
  const {AppContext, AppContextValue} = useAppData();
  const element = useRoutes(routes.map(({route}) => route));
  const location = useLocation();
 
  const reloadBackButton = () => {
    const back = () => {
      navigation(-1)
    }
    if(location.pathname === '/game'){
      tg.BackButton.hide()
      tg.BackButton.offClick(back)
      return
    }
    tg.BackButton.onClick(back)
    tg.BackButton.show()
    tg.disableVerticalSwipes()
  }
 
  useEffect(() => {
    tg.expand()
    reloadBackButton()
    // tg.onEvent('viewportChanged',reloadBackButton)
  }, [location])
  if (!element) return null;

  return (
    <AppContext.Provider value={AppContextValue} >
        <DartsTargetBack/>
        <AnimatePresence mode="sync" initial={false}>
          {React.cloneElement(element, { key: location.pathname })}
        </AnimatePresence>
       <Navigation/>
       </AppContext.Provider>
  );
}

export default App;
